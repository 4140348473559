import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import DashboardPage from "./pages/DashboardPage";
import CartComparison from "./pages/CartComparison";
import SingleProductPricing from "./pages/SingleProductPricing";
import SimilarProductPricing from "./pages/SimilarProductPricing";
import SingleProductPricingSelectedStores from "./pages/SingleProductPricingSelectedStores";
import PromoVSRegular from "./pages/PromoVSRegular";
import SingleProductPricingMap from "./pages/SingleProductPricingMap";
import NewProductsOverview from "./pages/NewProductsOverview";
import DiscontinuedProductsOverview from "./pages/DiscontinuedProductsOverview";
import NewProductsDetail from "./pages/NewProductsDetail";
import BiggestIncreasesOverview from "./pages/BiggestIncreasesOverview";
import BiggestIncreasesDetail from "./pages/BiggestIncreasesDetail";
import BiggestDropOverview from "./pages/BiggestDropOverview";
import BiggestDropDetail from "./pages/BiggestDropDetail";
// import List2MatchReport from "./pages/List2MatchReport";
import BattlegroundOverview from "./pages/BattlegroundOverview";
// import SRPPricingTool from "./pages/SRPPricingTool";
import ResetPasswordRequest from "./pages/ResetPasswordRequest";
import ResetPasswordRequestForm from "./pages/ResetPasswordRequestForm";
// import Settings from "./pages/Settings";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import NotFoundPage from "./pages/NotFoundPage";
// import MaintenancePage from "./pages/MaintenancePage";      
import PrivateRoute from "./PrivateRoute";
import YourReports from "./pages/YourReports";
import MyProducts from "./pages/MyProducts";
import MyProductComparison from "./pages/MyProductComparison";
import MyProductsElectrical from "./pages/MyProductsElectrical";
import MyProductComparisonElectrical from "./pages/MyProductComparisonElectrical";
import ProductCoverage from "./pages/ProductCoverage";
import ReportTrend from "./pages/ReportTrend";
import ReportTrendDetail from "./pages/ReportTrendDetail";
import CategoryTrend from "./pages/CategoryTrend";
import MapPage from "./pages/MapPage";
import SasktelDevices from "./pages/SasktelDevices";
import SasktelDeviceDetail from "./pages/SasktelDeviceDetail";
import SasktelPlans from "./pages/SasktelPlans";
import SasktelPlanDetail from "./pages/SasktelPlanDetail";
import SingleProductPricingElectrical from "./pages/SingleProductPricingElectrical";
import RequestedProducts from "./pages/RequestedProducts";
import ProductsByTag from "./pages/ProductsByTag";

function App() {   

  return (    
        <BrowserRouter>
          <Switch>
            <Route path="/" component={LoginPage} exact={true} />
            <Route path="/logout" component={LogoutPage} exact={true} />
            <Route path="/login" component={LoginPage} exact={true} />
          
            <PrivateRoute
              path="/dashboard"
              component={() => (
                <DashboardPage/>
              )}
              exact={true}
            />
            <PrivateRoute
              path="/new-products-overview"
              component={() => (
                <NewProductsOverview/>
              )}
            />
             <PrivateRoute
              path="/new-products-detail/:id"
              component={(props) => (
                <NewProductsDetail  {...props}/>
              )}
            />
            <PrivateRoute
              path="/discontinued-products"
              component={() => (
                <DiscontinuedProductsOverview/>
              )}
            />
            <Route
              path="/biggest-drop"
              component={() => (
                <BiggestDropOverview />
              )}
            />
            <PrivateRoute
               path="/biggest-drop-detail/:id"
               component={(props) => (
                 <BiggestDropDetail {...props}/>
               )}
             />                      
             <PrivateRoute
              path="/biggest-increase"
              component={() => (
                <BiggestIncreasesOverview/>
                )}
            />
             <PrivateRoute
              path="/biggest-increase-detail/:id"
              component={(props) => (
                <BiggestIncreasesDetail {...props}/>
              )}
            />         
            <PrivateRoute
              path="/battlegrounds"
              component={() => (
                <BattlegroundOverview/>
              )}
            />  
            <PrivateRoute
              path="/single-report-comparison"
              component={() => (
                <SingleProductPricing goBackButtonEnable/>
              )}
            />
            <PrivateRoute
              path="/single-report-electrical"
              component={() => (
                <SingleProductPricingElectrical goBackButtonEnable/>
              )}
            />
            <PrivateRoute
              path="/similar-product-pricing"
              component={() => (
                <SimilarProductPricing/>
              )}
            />
            <PrivateRoute
              path="/requested-products"
              component={() => (
                <RequestedProducts/>
              )}
            />
            <PrivateRoute
              path="/single-report-selected-stores"
              component={() => (
                <SingleProductPricingSelectedStores/>
              )}
            />
            <PrivateRoute
              path="/single-report"
              component={() => (
                <SingleProductPricing/>
              )}
            />  
            <PrivateRoute
              path="/promo-vs-regular"
              component={() => (
                <PromoVSRegular/>
              )}
            /> 
             <PrivateRoute
              path="/single-report-map"
              component={() => (
                <SingleProductPricingMap/>
              )}
            />    
             {/* <Route
              path="/single-report-map"
              component={SingleProductPricingMap}
              exact={true}
            />       */}
            <PrivateRoute
              path="/cart-comparison"
              component={() => (
                <CartComparison/>
              )}
            />
            <PrivateRoute
              path="/my-products"
              component={() => (
                <MyProducts/>
              )}
            />
             <PrivateRoute
              path="/my-product/:id"
              component={(props) => (
                <MyProductComparison {...props}/>
              )}
            />
            <PrivateRoute
              path="/my-products-electrical"
              component={() => (
                <MyProductsElectrical/>
              )}
            />
            <PrivateRoute
              path="/my-product-electrical/:id"
              component={(props) => (
                <MyProductComparisonElectrical {...props}/>
              )}
            />
            <PrivateRoute
              path="/product-coverage"
              component={(props) => (
                <ProductCoverage {...props}/>
              )}
            />
            <PrivateRoute
              path="/report-trends/:id"
              component={(props) => (
                <ReportTrend {...props}/>
              )}
            />
            <PrivateRoute
              path="/report-trend-detail/:report_id/:id"
              component={(props) => (
                <ReportTrendDetail {...props}/>
              )}
            />
            <PrivateRoute
              path="/category-trends"
              component={(props) => (
                <CategoryTrend {...props}/>
              )}
            />
            <PrivateRoute
              path="/sasktel-devices"
              component={(props) => (
                <SasktelDevices {...props}/>
              )}
            />
            <PrivateRoute
              path="/sasktel-device-detail/:id"
              component={(props) => (
                <SasktelDeviceDetail {...props} goBackButtonEnable/>
              )}
            />
            <PrivateRoute
              path="/sasktel-plans"
              component={(props) => (
                <SasktelPlans {...props}/>
              )}
            />
            <PrivateRoute
              path="/sasktel-plan-detail/:id"
              component={(props) => (
                <SasktelPlanDetail {...props} goBackButtonEnable/>
              )}
            />
            {/* <PrivateRoute
              path="/srp"
              component={() => (
                <SRPPricingTool/>
              )}
            /> */}
            {/* <Route path="/l2m" component={() => <List2MatchReport />} /> */}
            <Route
              path="/resetpassword/request"
              component={ResetPasswordRequest}
              exact={true}
            />
            <Route
              path="/resetpassword/:token"
              component={ResetPasswordRequestForm}
              exact={true}
            />
            <Route
              path="/your-reports"
              component={YourReports}
              exact={true}
            />
            <Route
              path="/map"
              component={MapPage}
              exact={true}
            />
             <PrivateRoute
              path="/products-by-tag/:id/:label"
              component={(props) => (
                <ProductsByTag {...props}/>
              )}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </BrowserRouter>    
  );
}
export default App;
