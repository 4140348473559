import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel ,
  Typography,
  Paper,
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import IconButton from "@material-ui/core/IconButton";
import LaunchIcon from "@material-ui/icons/Launch";

import { PriceComponent } from "../../utility/utility";
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ProductImage from "../Common/ProductImage.js";
import { useSelector } from "react-redux";
import LoadMore from "./LoadMore";
const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "16px",
    maxHeight: "92vh",
    boxShadow: "none",
  },
  hoverClickPopUp: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      // backgroundColor: theme.palette.secondary.main,
      // color: "#fff",
      fontWeight: "bold",
    },
  },
 
  storeLogo: {
    width: "auto",
    height: "50px",
    objectFit: "cover",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  sortLabel:{
    fontSize: "1rem",    
  },
  tableRow:{
    // cursor: "pointer",
    '& > .MuiTableCell-root':{
      padding:'8px'
    }
  },
  trHeader:{
    height:'64px',
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding:'16px 8px'
  },
  thHeaderFirst:{
    backgroundColor: "#DDD",
    paddingLeft:'24px'
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },
  productImg: {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    objectFit: "cover",
    display: "block",    
    marginLeft:'auto',
    marginRight:'auto'
  },
  showText:{
    textTransform:'capitalize '
  }
}));
export function SortLabel(props){
  const classes = useStyles();
  return (
    <TableSortLabel 
                {...props}
                className={classes.sortLabel}
                IconComponent={props=>(props.hideSortIcon?'':<FontAwesomeIcon icon={faSort} />)}
              >
                {props.nowrap?(<span style={{paddingRight:'5px',whiteSpace:'nowrap'}}>{props.children}</span>):
                (<span style={{paddingRight:'5px'}}>{props.children}</span>)}               
                </TableSortLabel>
  )
}

export function StoreLabel({stores,store_id}){
  const classes = useStyles();
  const currStore = stores.find((p) => p.id == Number(store_id)) 
  return (
    <div>
       <img className={classes.storeLogo} src={currStore.company_logo_link}/>
       <span>{currStore.name}</span>
    </div>
         
  )
} 
export default function Detail(props) {
  const classes = useStyles();
  const [dataSource, setDataSource] = useState(props.dataSource);
  const [orderDirection, setOrderDirection] = useState(1);
  const [limit, setLimit] = useState(50);
  const stores = useSelector(state => state.store.stores);

  const handleSort=(column)=>{
    setOrderDirection(-orderDirection);
    let tempDataSource = [...props.dataSource]
    column ==='old_price'||column =='new_price'||column =='changed_percentage_ea'?
    tempDataSource.sort((a,b) => (a[column] - b[column])*orderDirection)
    :tempDataSource.sort((a,b) => ((a[column] > b[column]) ? 1 : ((b[column] > a[column]) ? -1 : 0))*orderDirection);
    setDataSource(tempDataSource);
  }
  const handleRowClick =()=>{

  }
  const doLoadMore = () => {
    let tempLimit = limit+50
    setLimit(tempLimit);
  };
  // useEffect(() => {
  //   handleSort('changed_percentage_ea')
  // }
  // ,[])
   return (
    <>
      <TableContainer component={Paper} className={classes.container} square>
        <Table stickyHeader aria-label="new products overview">
          <TableHead>
            <TableRow  className={classes.trHeader}>
              <TableCell align="left"  className={classes.thHeader}></TableCell>
              <TableCell align="left"  className={classes.thHeader}>
                <SortLabel onClick={()=>handleSort('brand')}>Brand</SortLabel>
              </TableCell>
              <TableCell align="left"  className={classes.thHeader}>
                <SortLabel onClick={()=>handleSort('name')}>Name</SortLabel>
              </TableCell>
              <TableCell align="center"  className={classes.thHeader}>
                <SortLabel hideSortIcon>Size</SortLabel>
              </TableCell>
              <TableCell align="center"  className={classes.thHeader}>
                <SortLabel onClick={()=>handleSort('old_price')} nowrap="true">Old $</SortLabel>
              </TableCell>
              <TableCell align="center"  className={classes.thHeader}>
                <SortLabel onClick={()=>handleSort('new_price')} nowrap="true">New $</SortLabel>
              </TableCell>
              <TableCell align="center"  className={classes.thHeader}>
                <SortLabel onClick={()=>handleSort('changed_percentage_ea')} nowrap="true">% {props.isDrop?(<TrendingDownIcon />):(<TrendingUpIcon />)}</SortLabel>
              </TableCell>
              {/* <TableCell align="center"  className={classes.thHeader}>                           
                  <SortLabel hideSortIcon> Store</SortLabel>               
              </TableCell>
              <TableCell align="center"  className={classes.thHeader}>
                  <SortLabel hideSortIcon nowrap="true">View on store</SortLabel>     
               </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {
            dataSource &&
              dataSource.slice(0,limit).map((row, index) => {
                return (
                  <TableRow hover key={index} onClick={handleRowClick} className={classes.tableRow}>
                    <TableCell align="left">
                      <ProductImage  product_matching_group_id={row.product_matching_group_id} title={row.name} hide_multiple_icon width={'70px'} height={'70px'}/>
                    </TableCell>
                    <TableCell align="left" className={classes.showText}>{row.brand}</TableCell>
                    <TableCell align="left" className={classes.showText}>{row.name}</TableCell>
                    <TableCell align="center">{row.size}</TableCell>
                    <TableCell align="center"><PriceComponent value={row.old_price} /></TableCell>
                    <TableCell align="center"><PriceComponent value={row.new_price} /></TableCell>
                    <TableCell align="center">{row.changed_percentage_ea}%</TableCell>
                    {/* <TableCell align="center"><StoreLabel stores={stores} store_id={row.store_id}/></TableCell> */}
                    {/* <TableCell align="center">
                    <Typography variant="inherit" nowrap="true"> */}
                        {/* {index + 1} */}
                        {/* <IconButton
                          color="primary"
                          aria-label="product on shop"
                          component="span"
                          onClick={(event) => {
                            event.preventDefault();
                            window.open(
                              row.external_link ? row.external_link : "http://bettercart.ca"
                            );
                          }}
                        >
                          <LaunchIcon />
                        </IconButton> */}
                      {/* </Typography>
                    </TableCell> */}
                    
                  </TableRow>
                );
              })}
              {dataSource.length > limit&&(
                    <LoadMore doLoadMore={doLoadMore} />
              )}
          </TableBody>
          
        </Table>
      </TableContainer>
    </>
  );
}
