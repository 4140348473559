import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import { MemoizedMenu } from "../components/Menu";
import Overview from "../components/Biggest/Overview";
import axios from "axios";
import envConfig from "../bin/env.config";
import Loading from "../components/Loading/Loading";
import { format,addDays, parseISO } from 'date-fns'
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    padding:'0px',
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  contentDataTable: {
    marginTop: 15,
  },
}));

export default function BiggestIncreasesOverview(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const state = useSelector(state => state);
  const fromDate = state.week.weekSelected.first;
  const toDate = format(addDays(parseISO(state.week.weekSelected.first),6),'yyyy-MM-dd');
  const storesSelected = state.store.storesSelected
  const postData = {
    from_date: fromDate,
    to_date: toDate,
    storeIDs: storesSelected
      ? storesSelected.map((store) => store.id)
      : [],
    limit: 20,
    province: state.city.citySelected.province, 
  };

  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    const fetchDataSource = async () => {      
      // console.log('here'+searchText);
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/biggest-increases`;
      const result = await axios.post(url, postData,
                                      {
                                        headers: {
                                          Authorization: state.session.token,
                                        },
                                      });
      const dataSourceInit = result.data.data.sort(function(a, b){
                                  if(a.category_name < b.category_name) { return -1; }
                                  if(a.category_name > b.category_name) { return 1; }
                                  return 0;
                              })  

      setDataSource(dataSourceInit);
      console.log(dataSourceInit);
      setIsLoading(false);
    };
    fetchDataSource();
  }, [storesSelected, fromDate]);  
  return (
    <div className={classes.root}>
      <MemoizedMenu title={"Biggest Price Hikes for the Week of"} weekSelectedButtonEnable {...props} 
          selectedCityEnable
          selectedStoreEnable />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Overview dataSource={dataSource} />
            </>
          )}
        </Container>
      </main>
    </div>
  );
}
