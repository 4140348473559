import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import envConfig from "../bin/env.config.js";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  TablePagination
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { Menu } from "../components/Menu.js";
import Loading from "../components/Loading/Loading.js";
import { useDispatch, useSelector } from "react-redux";
// import { PriceComponent } from "../utility/utility.js";
import  ProductTagsInfo  from "../components/Product/ProductTagsInfo";

import  SummaryBox  from "../components/Product/SummaryBox.js";
import ProductImage from "../components/Common/ProductImage.js";
import SortLabel from "../components/Common/SortLabel.js";
import {
  selectProduct
} from "../store/actions/productAction.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    paddingBottom: theme.spacing(4),
    paddingLeft: "0",
    paddingRight: "0",
  },
  sortLabel:{
    fontSize: "1rem",    
  },
  tableContainer: {
    maxHeight: "calc(100vh - 128px)",
    boxShadow: "none",
  },
  tableRow:{
    '& > .MuiTableCell-root':{
      padding:'8px'
    },
    textTransform: 'capitalize',
    height:"130px"    
  },
  loadingMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
    width: '100%'
  },

  trHeader:{
    height:'64px',
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding:'16px 8px',
    whiteSpace: 'nowrap'
  },
  thHeaderFirst:{
    backgroundColor: "#DDD",
    paddingLeft:'24px',
    whiteSpace: 'nowrap'
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },  
  productImg: {
    width: "auto",
    maxHeight: "50px",
    objectFit: "cover",
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  waitingRow:{
    backgroundColor: '#ddd',
    animationDuration: '2s',
    highlightColor: '#a9b7c1',
    height:'70px',
    width:'100%',
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  waitingPicture:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ddd',
    marginLeft:'auto',
    marginRight:'auto'
  },
  hoverClickPopUp: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      fontWeight: "bold",
    },
  },
  searchContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  searchField: {
    width: "300px",
  },
}));

export function ProductRow({row, index}) {
  const classes = useStyles();  
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const urlSummaryMinMax = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/summary-min-max`;

  const handleRowClick = (row) => {
    let selectedProduct = state.product.products.find(x => x.id == row.id);
    dispatch(selectProduct(selectedProduct));
    history.push(`/single-report/`)       
  }

  return (
    <TableRow hover key={index} className={classes.tableRow}>
      <TableCell align="left" title={row.name}>
        <ProductImage product_matching_group_id={row.id} title={row.name} width={'50px'} height={'50px'} hide_multiple_icon="true"/>
      </TableCell>
      <TableCell align="left" title={row.name}>
        <span style={{display:"none"}}>{row.id} </span>    
        <span style={{cursor:"pointer"}} onClick={() => handleRowClick(row)}>{row.name} </span>   
        <ProductTagsInfo productMatchingGroupId={row.id}/>
      </TableCell>
      {/* <TableCell align="left">{row.brand}</TableCell> */}
      <TableCell align="left">{row.size}</TableCell>
      <TableCell align="right">
            <SummaryBox title="Min" apiUrl={urlSummaryMinMax} whichType='selected-min' productMatchingGroupID={row.id}/>                

      </TableCell>

      <TableCell align="right">
            <SummaryBox title="Max" apiUrl={urlSummaryMinMax} whichType='selected-max' productMatchingGroupID={row.id}/> 
      </TableCell>
    </TableRow>
  );
}

export default function ProductsByTag(props) {
  const classes = useStyles();
  const tagId = props.match.params.id;
  const tagName = props.match.params.label;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const state = useSelector((state) => state);
  const [dataSource, setDataSource] = useState([]);
  const [orderDirection, setOrderDirection] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [limit, setLimit] = useState(20);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const token = useSelector(state => state.session.token);
  const tableRef = React.useRef(null);

  const fetchDataSource = async (isLoadMore = false) => {
    if (isLoadMore) {
      setIsLoadingMore(true);
    } else {
      setIsLoading(true);
    }

    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/tagging/tagged-pmg-4view?orderBy=${orderBy}&order=${order}&limit=${limit}&skip=${skip}`;
    try {
      const result = await axios.post(
        url,
        { 
          tagId: tagId,
          q: searchText
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );   
      // console.log("Result:", result.data);
      const newItems = result.data.data

      if (isLoadMore) {
        setDataSource(prevData => [...prevData, ...newItems]);
      } else {
        setDataSource(newItems);
      }

      // Check if we've loaded all items
      setHasMore(dataSource.length + newItems.length < (result.data.data.total || 0));
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };
  useEffect(() => {
    // Reset skip when search text, order, or orderBy changes
    setSkip(0);
    fetchDataSource();
  }, [tagId, searchText, order, orderBy]);

  // Handle scroll event for lazy loading
  useEffect(() => {
    const handleScroll = () => {
      if (tableRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableRef.current;

        // When user scrolls to bottom (with a small threshold)
        if (scrollHeight - scrollTop - clientHeight < 100 && !isLoadingMore && hasMore) {
          loadMoreData();
        }
      }
    };

    const tableContainer = tableRef.current;
    if (tableContainer) {
      tableContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isLoadingMore, hasMore, skip, dataSource]);

  const loadMoreData = () => {
    if (!isLoadingMore && hasMore) {
      setSkip(prevSkip => prevSkip + limit);
      fetchDataSource(true);
    }
  };
  const handleSort = (column) => {
    setOrderDirection(-orderDirection);

    let tempData = [...dataSource];
    column === 'current_price_ea' ?
      tempData.sort((a, b) => (a[column] - b[column]) * orderDirection) :
      tempData.sort((a, b) => ((a[column] > b[column]) ? 1 : ((b[column] > a[column]) ? -1 : 0)) * orderDirection);
    setDataSource(tempData);
  }

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    if (event.key === 'Enter' || event.type === 'click') {
      setSkip(0);
      fetchDataSource();
    }
  };

  const handleClearSearch = () => {
    setSearchText("");
    setSkip(0);
    // Fetch data without search text
    setTimeout(() => {
      fetchDataSource();
    }, 0);
  };

  return (
    <div className={classes.root}>
      <Menu 
        goBackButtonEnable={true} 
        title={`Products by Tag: ${tagName} |`}
        selectedCityEnable
        selectedStoreEnable
        formDateToDateSelectedButtonEnable
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          <Divider />
          <div className={classes.searchContainer}>
            <TextField
              className={classes.searchField}
              variant="outlined"
              size="small"
              placeholder="Search products..."
              value={searchText}
              onChange={handleSearchChange}
              onKeyPress={handleSearchSubmit}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton size="small" onClick={handleSearchSubmit}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: searchText && (
                  <InputAdornment position="end">
                    <IconButton size="small" onClick={handleClearSearch}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Grid container spacing={3}>            
            <Grid item xs={12} md={12} lg={12}>
              {isLoading ? (
                <Loading />
              ) : (
                <TableContainer
                  className={classes.tableContainer}
                  component={Paper}
                  square
                  style={{background:"white"}}
                  ref={tableRef}
                >
                  <Table stickyHeader aria-label="products by tag">
                    <TableHead>
                      <TableRow className={classes.trHeader}>
                        <TableCell align="center" className={classes.thHeader} width='150px'></TableCell>
                        <TableCell align="left" className={classes.thHeader}>
                          <SortLabel onClick={() => handleSort("name")}>
                            Name
                          </SortLabel>
                        </TableCell>                        
                        <TableCell align="left" className={classes.thHeader}>
                          <SortLabel onClick={() => handleSort("size")}>
                            Size
                          </SortLabel>
                        </TableCell>
                        <TableCell align="center" className={classes.thHeader} width='120px'>
                          Min
                        </TableCell>
                        <TableCell align="center" className={classes.thHeader} width='120px'>
                          Max
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataSource && dataSource.map((row, index) => (
                        <ProductRow key={index} row={row} index={index} />
                      ))}
                      {isLoadingMore && (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <div className={classes.loadingMoreContainer}>
                              <Loading />
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}              
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}

