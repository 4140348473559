import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowStoreCountColumn
  } from "../../store/actions/myProductAction";
 
  import {      
    FormControlLabel,
    Checkbox,
    Typography
  } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    paddingLeft: 8
  },  
  title: {
    flexGrow: 1,
    display: "flex",
    justifyContent:"flex-start",
    alignItems:"center",
    paddingLeft: 16
  },
}));

export default function ShowStoreCountColumn() {
  const classes = useStyles();
  const showStoreCountColumn = useSelector(state => state.myProduct.showStoreCountColumn);
  const dispatch = useDispatch()
  const handleShowStoreCountColumn= ()=>{
    dispatch(setShowStoreCountColumn(!showStoreCountColumn))
  }
  return (  
    <>
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        nowrap="true"
        className={classes.title}
      >
       <span></span>
      </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={showStoreCountColumn}
              onChange={handleShowStoreCountColumn}
              name="Show the store count column"
              color="secondary"
            />
          }
          label="Show the store count column"
        />
    </>    
   
  );
}
