import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import envConfig from "../../bin/env.config";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function ProductTagsInfo({productMatchingGroupId}) {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const token = useSelector((state) => state.session.token);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/group/get-tag-4view/${productMatchingGroupId}`;
      try {
        const result = await axios(url, {
          headers: {
            Authorization: token,
          },
        });      
        setDataSource(result.data.data);
      } catch (error) {
        console.error("Error fetching tags:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [productMatchingGroupId, token]);

  const handleTagClick = (tag) => {
    history.push(`/products-by-tag/${tag.value}/${encodeURIComponent(tag.label)}`);
  };

  return (
    <div className={classes.container}>
      {isLoading ? (          
        <CircularProgress className={classes.loading} />
      ) : (
        dataSource.map((item, index) => (
          <Chip 
            size="small"
            key={`tag-${index}`} 
            label={item.label} 
            onClick={() => handleTagClick(item)}
            clickable
          />
        ))
      )}
    </div>       
  );
}

