import {GET_CITIES, SELECT_CITY, CITY_ERROR} from '../types'
import axios from 'axios'
import envConfig from "../../bin/env.config";

export const fetchCities = (province) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/analytics/cities/${province}`;
      axios.get(url, {
        headers: {
          'Authorization': JSON.stringify(getState().session.token)
        } 
      }).then((result) => {
        let cities = [...result.data.data];
        dispatch( {
            type: GET_CITIES,
            cities: cities
        })
        // Find the default city or use the first city
        const defaultCity = cities.find(city => city.is_default === 1) || cities[0];
        if (defaultCity) {
          dispatch(selectCity(defaultCity));
          resolve(defaultCity);
        } else {
          resolve(null);
        }        
      }).catch(error => {
        reject(error);
        dispatch( {
            type: CITY_ERROR,
            payload: error,
        })
      })
    });
  };
};

export const selectCity = (city) => {
    return (dispatch)=>{
      dispatch( {
        type: SELECT_CITY,
        citySelected: city
      })
    }
}