import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { red } from '@material-ui/core/colors';
import { useDispatch, useSelector } from "react-redux";
import ProductImage from "../Common/ProductImage.js";
import {
  removeFromCart
  } from "../../store/actions/cartAction.js";
const useStyles = makeStyles((theme) => ({
  productImgColumn: {
    position: 'relative',
    padding: "0px 5px",
    '&:hover img': {
      opacity: "0.5"
    },
    '&:hover svg': {
      opacity: "1"
    },
    '& div>img': {
      margin: 0
    }
  },
  productImg: {
    width: "auto",
    height: "50px",
    maxWidth: "50px",
    objectFit: "cover",
    display: "block",
    opacity: "1"
  }, 
  removeIcon:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: "0",    
  }
}));

export default function ProductItem({product}) {
  const classes = useStyles();  
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart);
  const addDefaultSrc = (event) => {
    event.target.src = "/resources/images/no-picture-available.png";
  };
  const handleRemoveFromCart = ()=>{
    dispatch(removeFromCart(product))
  }
  return (    
            <div className={classes.productImgColumn}  onClick={handleRemoveFromCart}> 
              <ProductImage 
                product_matching_group_id={product.id}
                title={product.fullname} 
                width={'50px'} 
                height={'50px'} 
                hide_multiple_icon="true"
              />
              {/* <RemoveCircleOutlineIcon style={{ color: red[500] }} className={classes.removeIcon} onClick={handleRemoveFromCart}/> */}
              <HighlightOffIcon style={{ color: red[600] }} className={classes.removeIcon} onClick={handleRemoveFromCart}/>
            </div>
  );
  
}
