import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/free-solid-svg-icons'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
// import ArrowBackIosIcon from '@material-ui/core/ArrowBackIos';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../Dialog/DialogCustom";
import axios from "axios";
import envConfig from "../../bin/env.config";
import CircularProgress from "@material-ui/core/CircularProgress";
import VizSensor from "react-visibility-sensor";
import CompanyLogo from "./CompanyLogo";
const useStyles = makeStyles((theme) => ({
  productImage: {
    objectFit: "contain", //
    margin: theme.spacing(1),
    marginLeft: theme.spacing(3),
  }, 
  loading: {
    color: theme.palette.secondary.main,
  },
  imageContainer: {
    display: "block",
    position: 'relative',
    "&:hover": {
      cursor: "pointer",
      color: "rgb(0 176 79 / 100%)",
    },
  },
  faImagesIcon:{
    position: "absolute", 
    top:"10px", 
    left:"10px"
  },
  divImageContainer: {
    display: "flex",
    width:"100%",
    height:"400px",
    alignItems: "center", 
    justifyContent:"center",
    position: "relative",
    "&:hover": {
      cursor: "pointer",      
    },
  },
}));
export function ImageDialog({    
  open,
  handleClose,
  imageList,
}) {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  
  const handleClickNext =()=>{
    let n= imageList.length
    let nextIndex = selectedIndex+1
    nextIndex = nextIndex>=n? 0 : nextIndex
    setSelectedIndex(nextIndex)

  }
  const handleClickBack =()=>{
    let n= imageList.length
    let backIndex = selectedIndex-1
    backIndex = backIndex<0? n-1 : backIndex
    setSelectedIndex(backIndex)
  }
  const addDefaultSrc = (event)=>{
    event.target.src = '/resources/images/no-picture-available.png'
  }
  return (   
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="ImageDialog"
        open={open}
      >
        <DialogTitle id="image" onClose={handleClose}>
          {/* {imageList.length > 0 &&(
            
            
            )} */}
            {/* <Button  variant={"outlined"} >{imageList.length}</Button> */}
          {imageList.length} images 
          <IconButton
              color="inherit"
              onClick={handleClickBack}
              aria-label="Close"
            >
              
              <NavigateBeforeIcon/>
              {/* <FontAwesomeIcon icon={faChevronLeft} />  */}
            </IconButton>
          
          {selectedIndex+1}/{imageList.length} 
          <IconButton
              color="inherit"
              onClick={handleClickNext}
              aria-label="Close"
            >
            <NavigateNextIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.divImageContainer} onClick={handleClickNext}>
          <img
              className={classes.productImage}
              style={{ width: '300px',maxHeight:"400px"}}
              src={
                imageList[selectedIndex]
                  ? imageList[selectedIndex].image_url
                  : `https://bettercart.ca/resources/images/no-picture-available.png`
              }
              onError={addDefaultSrc}
            />
            {imageList[selectedIndex]&&<CompanyLogo company_id={imageList[selectedIndex].company_id} style={{position: "absolute",bottom:"0px", right:"0px",width:"100px"}}/>}
          </div>
        </DialogContent>
        
      </Dialog>    
  );
}
export default function ProductImage(props) {
  const classes = useStyles();
  const {product_id, product_matching_group_id,  company_logo_width, hide_multiple_icon} =props
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  
  const state = useSelector(state => state);
  const [isVisible, setIsVisible] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [imageSelectedIndex, setImageSelectedIndex] = useState(0);
  const handleClickOpen = () => {
    !hide_multiple_icon&&setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const addDefaultSrc = (event)=>{
    event.target.src = '/resources/images/no-picture-available.png'
  }
  useEffect(() => {
    const fetchDataSource = async () => {
      setIsLoading(true);
      const apiUrl = `${envConfig.API_ROOT}${envConfig.API_VERSION}/matching/product-images`;
      const postData = {
        product_id: product_id,
        product_matching_group_id:product_matching_group_id,
      };
      try {
        const result = await axios.post(apiUrl, postData,
          {
            headers: {
              Authorization: state.session.token,
            },
          });
        setImageList(result.data.data);        
      } catch (e) {
        console.log(e)
      }
      setIsLoading(false);
    };
    isVisible&& fetchDataSource();
  }, [isVisible,product_id, product_matching_group_id]);
  
  const doLoadData = (isVisibleChanged) => {
    isVisibleChanged&&setIsVisible(isVisibleChanged)    
  };  
  return (
    <VizSensor onChange={doLoadData}>
      <>
        {isLoading ? (          
          <CircularProgress className={classes.loading} />
        ) : (
          
              <div className={!hide_multiple_icon?classes.imageContainer:undefined }  onClick={handleClickOpen}>
                {!hide_multiple_icon&&<FontAwesomeIcon className={classes.faImagesIcon} icon={imageList.length > 1?faImages:faImage} />}
                <img                  
                  className={classes.productImage}
                  {...props}
                  src={
                    imageList[imageSelectedIndex]
                      ? imageList[imageSelectedIndex].image_url
                      : `https://bettercart.ca/resources/images/no-picture-available.png`
                  }
                  onError={addDefaultSrc}
                />
                {!hide_multiple_icon&&imageList[imageSelectedIndex]&&<CompanyLogo company_id={imageList[imageSelectedIndex].company_id} 
                style={{position: "absolute", bottom:"20px", right:"10px", width:company_logo_width?company_logo_width:'50px'}}/>}
              </div> 
        )}
        <ImageDialog imageList={imageList} open={open} handleClose={handleClose}/>
      </>
    </VizSensor>
      
  );
}
