import React, { useState,useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel ,
  Paper,
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faSort } from '@fortawesome/free-solid-svg-icons'

import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';

import ProductImage from "../Common/ProductImage.js";

const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "16px",
    maxHeight: "92vh",
    boxShadow: "none",
  },
  hoverClickPopUp: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      // backgroundColor: theme.palette.secondary.main,
      // color: "#fff",
      fontWeight: "bold",
    },
  },
 
  storeLogo: {
    width: "auto",
    height: "50px",
    objectFit: "cover",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  sortLabel:{
    fontSize: "1rem",    
  },
  trRow:{
    cursor: "pointer",    
  },
  tdColumn:{    
    padding:'8px',
    textTransform:'capitalize'    
  },
  tdColumnFirst:{
    paddingLeft:'24px',
    textTransform:'capitalize'  
  },
  trHeader:{
    height:'64px'
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding:'16px 8px'
  },
  thHeaderFirst:{
    backgroundColor: "#DDD",
    paddingLeft:'24px'
  },
  
  productImgContainer: {
    display: "flex",
    justifyContent:"flex-start",
    width: "400px"
  },
  productImgColumn: {
    // flex: "20%",
    padding: "0px 5px",
  },
  productImg: {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    objectFit: "cover",
    display: "block",
  },
  noOfProducts:{
    fontSize: "1rem",
    fontWeight: "500",
    color: 'rgba(0, 0, 0, 1)'
  }
}));
export function SortLabel(props){
  const classes = useStyles();
  return (
    <TableSortLabel 
                {...props}
                className={classes.sortLabel}
                IconComponent={props=>(<FontAwesomeIcon icon={faSort} />)}
              >
                <span style={{paddingRight:'5px'}}>{props.children}</span>                
                </TableSortLabel>
  )
}
export function ProductList({products}){
  const classes = useStyles();
  return (
    <div className={classes.productImgContainer}>
    {products &&
     products.slice(0,5).map((productMatchingGroupId, index) => {
      return (
        <div key={index} className={classes.productImgColumn}>
          <ProductImage  product_matching_group_id={productMatchingGroupId} title={""} hide_multiple_icon width={'70px'} height={'70px'}/>           
        </div>
      )}
      )}
    </div>
  )
} 
export default function Overview(props) {
  const classes = useStyles();
  const history = useHistory();
  const [dataSource, setDataSource] = useState(props.dataSource);
  const [orderDirection, setOrderDirection] = useState(1);
  const handleSort=(column)=>{

    setOrderDirection(-orderDirection);
    let tempDataSource = [...props.dataSource]
    if(['total_products', 'max_price_changed_percent', 'min_price_changed_percent'].includes(column)){
      tempDataSource.sort((a,b) => (a[column] - b[column])*orderDirection);
    } else {
      tempDataSource.sort((a,b) => (a[column] > b[column]) ? 1 : ((b[column] > a[column]) ? -1 : 0)*orderDirection);
    }
    setDataSource(tempDataSource);
  }
  const handleRowClick =(categoryId)=>{
    !props.isDrop?history.push(`/biggest-increase-detail/${categoryId}`)
    :history.push(`/biggest-drop-detail/${categoryId}`);
  }
  // console.log(props)
  return (
    <>
      <TableContainer component={Paper} className={classes.container} square>
        <Table stickyHeader aria-label="biggest hikes overview">
          <TableHead >
            <TableRow  className={classes.trHeader}>
              <TableCell align="left" className={classes.thHeaderFirst}>
              <SortLabel onClick={()=>handleSort('category_name')}>Category</SortLabel>
              </TableCell>
              <TableCell align="center" className={classes.thHeader}>
              <SortLabel onClick={()=>handleSort('total_products')} nowrap="true"># of Products with Price{props.isDrop?(<TrendingDownIcon />):(<TrendingUpIcon />)}</SortLabel>
              </TableCell>
              <TableCell align="center" className={classes.thHeader} >
              <SortLabel  onClick={()=>handleSort('min_price_changed_percent')} >Min % {props.isDrop?(<TrendingDownIcon />):(<TrendingUpIcon />)}</SortLabel>                
              </TableCell>
              <TableCell align="center" className={classes.thHeader} >
              <SortLabel  onClick={()=>handleSort('max_price_changed_percent')} >Max % {props.isDrop?(<TrendingDownIcon />):(<TrendingUpIcon />)}</SortLabel>                
              </TableCell>
              <TableCell align="left" className={classes.thHeader}></TableCell>
              <TableCell align="right" className={classes.thHeader}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
            dataSource &&
              dataSource.map((row, index) => {
                // totalProduct = totalProduct + row.number_of_products ;
                return (
                  <TableRow hover key={index} onClick={()=>handleRowClick(row.category_id)} className={classes.trRow}>
                    <TableCell align="left" className={classes.tdColumnFirst}>
                        {row.category_name}
                    </TableCell>
                    <TableCell align="center"  className={classes.tdColumn}>{row.total_products}</TableCell>
                    <TableCell align="center"  className={classes.tdColumn}>{Math.abs(row.min_price_changed_percent)}%</TableCell>
                    <TableCell align="center"  className={classes.tdColumn}>{Math.abs(row.max_price_changed_percent)}%</TableCell>
                    <TableCell align="left"  className={classes.tdColumn}>
                      <ProductList products={row.product_matching_group_ids}/>
                    </TableCell>
                    <TableCell align="right" className={classes.tdColumn}>
                      <FontAwesomeIcon icon={faChevronRight} size="3x" style={{color:'rgba(0, 0, 0, 0.1)'}} />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
         
        </Table>
      </TableContainer>
    </>
  );
}
