import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import { MemoizedMenu } from "../components/Menu";
import Detail from "../components//Biggest/Detail";
import axios from "axios";
import envConfig from "../bin/env.config";
import Loading from "../components/Loading/Loading";
import { Helpers } from "../utility/helpers";
import { format,addDays, parseISO } from 'date-fns'
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    padding:'0px',
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  contentDataTable: {
    marginTop: 15,
  },
}));

export default function BiggestIncreasesDetail(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const state = useSelector(state => state);  
  
  const fromDate = state.week.weekSelected.first;
  const toDate = format(addDays(parseISO(state.week.weekSelected.first),6),'yyyy-MM-dd');
  const storesSelected = state.store.storesSelected
  const postData = {
    from_date: fromDate,
    to_date: toDate,
    storeIDs: storesSelected
      ? storesSelected.map((store) => store.id)
      : [],
    categoryId: props.match.params.id,
    limit: -1,
    province: state.city.citySelected.province,    
  };

  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    const fetchDataSource = async () => {      
      // console.log('here'+searchText);
      setIsLoading(true);
      const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/srp-pricing-tool/biggest-increases-detail`;
      const result = await axios.post(url, postData,
                                      {
                                        headers: {
                                          Authorization: state.session.token,
                                        },
                                      });
                                      
      // let tempDataSource = result.data.data[0]?result.data.data[0]:[];     
      console.log(result.data.data);
      setDataSource(result.data.data);
      setIsLoading(false);
    };
    fetchDataSource();
  }, [storesSelected,fromDate]);
  return (
    <div className={classes.root}>
      <MemoizedMenu title={`Biggest Price Hikes in ${Helpers.titleCase(dataSource.category_name)} for the Week of`} 
      weekSelectedButtonEnable {...props} goBackButtonEnable
        selectedCityEnable
        selectedStoreEnable
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Detail dataSource={dataSource} stores={props.stores} />
            </>
          )}
        </Container>
      </main>
    </div>
  );
}
