import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel ,
  Typography,
  Paper,
} from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import IconButton from "@material-ui/core/IconButton";
import LaunchIcon from "@material-ui/icons/Launch";

import { convertToViewDate,PriceComponent } from "../../utility/utility";

const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "16px",
    maxHeight: "92vh",
    boxShadow: "none",
  },
  hoverClickPopUp: {
    cursor: "pointer",
    textDecoration: "underline",
    "&:hover": {
      // backgroundColor: theme.palette.secondary.main,
      // color: "#fff",
      fontWeight: "bold",
    },
  },
 
  storeLogo: {
    width: "auto",
    height: "50px",
    objectFit: "cover",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
  },
  sortLabel:{
    fontSize: "1rem",    
  },
  tableRow:{
    // cursor: "pointer",
    '& > .MuiTableCell-root':{
      padding:'8px'
    }
  },
  trHeader:{
    height:'64px'
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding:'16px 8px'
  },
  thHeaderFirst:{
    backgroundColor: "#DDD",
    paddingLeft:'24px'
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },
  productImg: {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    objectFit: "cover",
    display: "block",
  },
  showText:{
    textTransform:'capitalize '
  }
}));
export function SortLabel(props){
  const classes = useStyles();
  return (
    <TableSortLabel 
                {...props}
                className={classes.sortLabel}
                IconComponent={props=>(props.hideSortIcon?'':<FontAwesomeIcon icon={faSort} />)}
              >
                <span style={{paddingRight:'5px'}}>{props.children}</span>                
                </TableSortLabel>
  )
}
export function ProductImages({product}){
  const classes = useStyles();
  const addDefaultSrc = (event)=>{
    event.target.src = '/resources/images/no-picture-available.png'
  }
  return (
    <img className={classes.productImg} src={product.image_url} onError={addDefaultSrc}/>      
    )
} 

export default function Detail(props) {
  const classes = useStyles();
  const [dataSource, setDataSource] = useState(props.dataSource);
  const [orderDirection, setOrderDirection] = useState(1);
  const handleSort=(column)=>{

    setOrderDirection(-orderDirection);
    let tempDataSource = [...props.dataSource]
    column =='current_price_ea'?
    tempDataSource.sort((a,b) => (a[column] - b[column])*orderDirection)
    :tempDataSource.sort((a,b) => (a[column] > b[column]) ? 1 : ((b[column] > a[column]) ? -1 : 0)*orderDirection);
    setDataSource(tempDataSource);
  }
  const handleRowClick =()=>{

  }
   return (
    <>
      <TableContainer component={Paper} className={classes.container} square>
        <Table stickyHeader aria-label="new products overview">
          <TableHead>
            <TableRow  className={classes.trHeader}>
              <TableCell align="left"  className={classes.thHeader}>

              </TableCell>
              <TableCell align="left"  className={classes.thHeader}>
                <SortLabel onClick={()=>handleSort('brand')}>Brand</SortLabel>
              </TableCell>
              <TableCell align="left"  className={classes.thHeader}>
                <SortLabel onClick={()=>handleSort('name')}>Name</SortLabel>
              </TableCell>
              <TableCell align="center"  className={classes.thHeader}>
                <SortLabel hideSortIcon>Size</SortLabel>
              </TableCell>
              <TableCell align="center"  className={classes.thHeader}>
                <SortLabel onClick={()=>handleSort('current_price_ea')}>Price</SortLabel>
              </TableCell>
              <TableCell align="center"  className={classes.thHeader}>                           
                  <SortLabel hideSortIcon> Date added  </SortLabel>               
              </TableCell>
              <TableCell align="center"  className={classes.thHeader}>
                  <SortLabel hideSortIcon>View on store</SortLabel>     
               </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
            dataSource &&
              dataSource.map((row, index) => {
                return (
                  <TableRow hover key={index} onClick={handleRowClick} className={classes.tableRow}>
                    <TableCell align="left">
                      <ProductImages product={row}/>
                    </TableCell>
                    <TableCell align="left" className={classes.showText}>{row.brand}</TableCell>
                    <TableCell align="left" className={classes.showText}>{row.name}</TableCell>
                    <TableCell align="center">{row.size}</TableCell>
                    <TableCell align="center"><PriceComponent value={row.current_price_ea} /></TableCell>
                    <TableCell align="center"><span style={{transition:'pre'}}>{convertToViewDate(row.created_at)}</span></TableCell>
                    <TableCell align="center">
                    <Typography variant="inherit" nowrap="true">
                        {/* {index + 1} */}
                        <IconButton
                          color="primary"
                          aria-label="product on shop"
                          component="span"
                          onClick={(event) => {
                            event.preventDefault();
                            window.open(
                              row.external_link ? row.external_link : "http://bettercart.ca"
                            );
                          }}
                        >
                          <LaunchIcon />
                        </IconButton>
                      </Typography>
                    </TableCell>
                    
                  </TableRow>
                );
              })}
          </TableBody>
          
        </Table>
      </TableContainer>
    </>
  );
}
