import React, { useState, useEffect, useRef, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { alpha } from '@material-ui/core/styles'
import Divider from "@material-ui/core/Divider";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { selectProduct } from "../store/actions/productAction";
import envConfig from "../bin/env.config";
import Loading from "../components/Loading/Loading";
import {Menu} from "../components/Menu";
import ProductImage from "../components/Common/ProductImage";
import SortLabel from "../components/Common/SortLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
  },
  container: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: "0",
    paddingRight: "0",
  },
  sortLabel:{
    fontSize: "1rem",    
  },
  tableContainer: {
    // padding: "16px",
    maxHeight: "calc(100vh - 188px)",
    boxShadow: "none",
  },
  tableRow:{
    // cursor: "pointer",
    '& > .MuiTableCell-root':{
      padding:'8px'
    },
    textTransform: 'capitalize',
    cursor: "pointer",    
  },
  trHeader:{
    height:'64px',
  },
  thHeader: {
    backgroundColor: "#DDD",
    padding:'16px 8px'
  },
  thHeaderFirst:{
    backgroundColor: "#DDD",
    paddingLeft:'24px'
  },
  tableFooter: {
    height: "50px",
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },  
  productImg: {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    objectFit: "cover",
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  waitingRow:{
    backgroundColor: '#ddd',
    animationDuration: '2s',
    highlightColor: '#a9b7c1',
    height:'70px',
    width:'100%',
    display: "block",
    marginLeft:'auto',
    marginRight:'auto'
  },
  waitingPicture:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ddd',
    height:'70px',
    width:'70px',
    marginLeft:'auto',
    marginRight:'auto'
  },
  loadingMore: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  searchContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  searchField: {
    width: '300px',
  },
}));

export default function ProductCoverage() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const state = useSelector((state) => state);
  const fromDate = state.week.fromDateSelected.date;
  const toDate = state.week.toDateSelected.date; 
  const storesSelected = state.store.storesSelected
  const bannersSelected = state.store.bannersSelected
  const byBanner = state.store.byBanner

  const [limit, setLimit] = useState(20);
  const [skip, setSkip] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("cnt_banners");
  const [searchText, setSearchText] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const tableContainerRef = useRef();
  // Use debounce to prevent API calls on every keystroke
  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchQuery(searchText);
      setSkip(0); // Reset data when search changes
      setDataSource([]);
    }, 500); // 500ms debounce delay

    return () => clearTimeout(timer);
  }, [searchText]);

  useEffect(() => {
    setSkip(0);
    setDataSource([]);
    setHasMore(true);
    fetchDataSource(true);
  }, [fromDate, toDate, storesSelected, bannersSelected, byBanner, orderBy, order, searchQuery]);

  const fetchDataSource = async (reset = false) => {
    if (reset) {
      setIsLoading(true);
    } else {
      setIsLoadingMore(true);
    }
    const url = `${envConfig.API_ROOT}${envConfig.API_VERSION}/report/product-coverage`;
    const postData = {
      from_date: fromDate,
      to_date: toDate,
      storeIDs: storesSelected ? storesSelected.map((store) => store.id) : [],
      bannerIDs: bannersSelected,
      limit: limit,
      skip: reset ? 0 : skip,
      q: searchQuery,
      order: order,
      orderBy: orderBy,
    };

    try {
      const result = await axios.post(url, postData, {
        headers: {
          Authorization: JSON.stringify(state.session.token),
        },
      });

      const newItems = result.data.data;

      if (reset) {
        setDataSource(newItems);
        setSkip(newItems.length);
      } else {
        setDataSource(prevData => [...prevData, ...newItems]);
        setSkip(prevSkip => prevSkip + newItems.length);
      }

      setHasMore(newItems.length === limit);
      setIsLoading(false);
      setIsLoadingMore(false);
    } catch (error) {
      console.error("Error fetching product coverage data:", error);
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  const handleSort = (column) => {
    if(column === orderBy) {
      setOrder(order === 'asc' ? 'desc' : 'asc')
    } else {
      setOrderBy(column)
    }
  }

  const handleRowClick = (row) => {
    let selectedProduct = state.product.products.find(x => x.id == row.product_matching_group_id)
    dispatch(selectProduct(selectedProduct))
    history.push(`/single-report/${row.product_matching_group_id}`)
  }

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);    
  };

  // Clear search
  const handleClearSearch = () => {
    setSearchText("");
    setSearchQuery("");
  };

  // Infinite scroll implementation
  const lastItemRef = useCallback(node => {
    if (isLoading || isLoadingMore) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        fetchDataSource();
      }
    });

    if (node) observer.current.observe(node);
  }, [isLoading, isLoadingMore, hasMore]);

  // Handle scroll event for the table container
  useEffect(() => {
    const container = tableContainerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollHeight - scrollTop - clientHeight < 100 && !isLoading && !isLoadingMore && hasMore) {
        fetchDataSource();
      }
    };

    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [isLoading, isLoadingMore, hasMore]);
  return (
    <div className={classes.root}>
      <Menu title={"Product Coverage for "}  
        formDateToDateSelectedButtonEnable
        selectedCityEnable
        selectedStoreEnable
        // byBannerEnable
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={false} className={classes.container}>
          <Divider />
          <div className={classes.searchContainer}>
              <TextField
                className={classes.searchField}
                size="small"
                variant="outlined"
                placeholder="Search products..."
                value={searchText}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment size="small" position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: searchText && (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={handleClearSearch}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <TableContainer 
                component={Paper} 
                className={classes.tableContainer}
                ref={tableContainerRef}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow className={classes.trHeader}>
                      <TableCell className={classes.thHeaderFirst}>#</TableCell>
                      <TableCell className={classes.thHeaderFirst}>
                        <SortLabel
                          className={classes.sortLabel}
                          active={orderBy === "fullname"}
                          direction={orderBy === "fullname" ? order : "asc"}
                          onClick={() => handleSort("fullname")}
                        >
                          Product
                        </SortLabel>
                      </TableCell>
                      <TableCell className={classes.thHeader}>
                        <SortLabel
                          className={classes.sortLabel}
                          active={orderBy === "cnt_banners"}
                          direction={orderBy === "cnt_banners" ? order : "asc"}
                          onClick={() => handleSort("cnt_banners")}
                        >
                          Banners
                        </SortLabel>
                      </TableCell>
                      <TableCell className={classes.thHeader}>
                        <SortLabel
                          className={classes.sortLabel}
                          active={orderBy === "cnt_stores"}
                          direction={orderBy === "cnt_stores" ? order : "asc"}
                          onClick={() => handleSort("cnt_stores")}
                        >
                          Stores
                        </SortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataSource.map((row, index) => (
                      <TableRow
                        key={row.product_matching_group_id}
                        className={classes.tableRow}
                        ref={index === dataSource.length - 1 ? lastItemRef : null}
                      >
                        <TableCell component="th" scope="row" width={"120px"}>
                          <ProductImage 
                            product_matching_group_id={row.product_matching_group_id}
                            title={row.fullname} 
                            width={'50px'} 
                            height={'50px'} 
                            company_logo_width={'20px'}
                          />
                        </TableCell>
                        <TableCell><div onClick={() => handleRowClick(row)}>{row.fullname}</div></TableCell>
                        <TableCell >{row.cnt_banners}</TableCell>
                        <TableCell >{row.cnt_stores}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {isLoadingMore && (
                  <div className={classes.loadingMore}>
                    <Loading />
                  </div>
                )}
              </TableContainer>
            </>
          )}
        </Container>
      </main>
    </div>
  );
}


